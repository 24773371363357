import UsersController from "./users_controller";
import datatables_initialize from "../datatables_initialize";
import yadcf_wrapper from "../yadcf_wrapper";

export default class extends UsersController {
  connect() {
    super.connect();

    const tableElement = $(this.element).find("table.dataTable");

    if (!tableElement || tableElement.length == 0) {
      console.warn("DataTable not found!");
      return;
    }

    const dataTable = datatables_initialize(tableElement);

    yadcf_wrapper(dataTable, [
      {
        filter_type: "text",
      },
      {
        filter_type: "text",
      },
      {},
      {
        filter_type: "none",
      },
    ]);
  }
}
