import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String, city_id: String, institution_url: String };
  static targets = [
    "city",
    "institution",
    "distance_input",
    "steps_input",
    "city_select",
    "city_field",
    "city_link",
    "institution_select",
    "institution_field",
    "institution_link",
    "display_city_select",
    "display_institution_select",
  ];

  connect() {
    const that = this;

    $(this.cityTarget)
      .select2({
        placeholder: "Ort meiner Kita auswählen...",
        language: "de",
        theme: "bootstrap",
        ajax: {
          delay: 250,
          url: this.urlValue,
          dataType: "json",
          // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
        },
      })
      .on("select2:select", function (e) {
        let city = e.params.data.id;

        that.update_institution(city, null);
      });

    if (this.cityIdValue) {
      this.update_institution(
        this.cityIdValue,
        $(this.institutionTarget).val()
      );
    } else {
      $(this.institutionTarget).select2({
        placeholder: "Kita auswählen...",
        language: "de",
        theme: "bootstrap",
        disabled: true,
      });
    }

    const distance_field = $(this.distance_inputTarget);
    const steps_field = $(this.steps_inputTarget);

    steps_field.prop("disabled", distance_field.val() !== "");
    distance_field.prop("disabled", steps_field.val() !== "");

    distance_field.on("change paste keyup", () => {
      steps_field.prop("disabled", distance_field.val() !== "");
    });

    steps_field.on("change paste keyup", () => {
      distance_field.prop("disabled", steps_field.val() !== "");
    });

    $(this.city_linkTarget).click((e) => {
      $(this.city_selectTarget).hide();
      $(this.institution_selectTarget).hide();
      $(this.city_fieldTarget).show();
      $(this.institution_fieldTarget).show();
      $(this.display_city_selectTarget).val("0");
      $(this.display_institution_selectTarget).val("0");
      e.preventDefault();
    });

    $(this.institution_linkTarget).click((e) => {
      $(this.institution_selectTarget).hide();
      $(this.institution_fieldTarget).show();
      $(this.display_institution_selectTarget).val("0");
      e.preventDefault();
    });
  }

  update_institution(city_id, institution_value) {
    let institution = $(this.institutionTarget);

    $.ajax({
      type: "GET",
      url: this.institutionUrlValue.replace(/\d*$/, city_id),
      success: (data) => {
        if (institution.hasClass("select2-hidden-accessible")) {
          institution.val(null).empty().trigger("change");
          institution.select2("destroy");
        }
        institution.prop("disabled", false);
        institution.select2({
          placeholder: "Kita auswählen...",
          language: "de",
          theme: "bootstrap",
          data: data.results,
        });
        institution.val(institution_value).trigger("change");
      },
    });
  }
}
