import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    super.connect();

    const imageElement = $(this.element).find("img.qr");

    setInterval(function () {
      let src = imageElement.attr("src").split("?")[0];
      src = src + "?ts=" + new Date().getTime();
      imageElement.attr("src", src);
    }, 1000);
  }
}
