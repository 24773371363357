import { Controller } from "stimulus";
import datatables_initialize from "../datatables_initialize";
import yadcf_wrapper from "../yadcf_wrapper";

export default class extends Controller {
  connect() {
    super.connect();

    const tableElement = $(this.element).find("table.dataTable");

    if (!tableElement || tableElement.length == 0) {
      console.warn("DataTable not found!");
      return;
    }

    const dataTable = datatables_initialize(tableElement);

    dataTable.column(10).visible(false);

    const buttons = new $.fn.dataTable.Buttons(dataTable, {
      buttons: [
        {
          extend: "excel",
          exportOptions: {
            format: {
              body: function (data, rowIdx, columnIdx, _node) {
                if (columnIdx === 6) {
                  return data.replace(".", "").replace(",", ".");
                } else if (columnIdx === 0 || columnIdx === 11) {
                  return "";
                } else {
                  return data;
                }
              },
            },
          },
        },
      ],
    });

    buttons
      .container()
      .find("button")
      .css("float", "right")
      .css("margin", "0px 8px")
      .insertBefore("div.page-header > h1");

    yadcf_wrapper(dataTable, [
      {
        filter_type: "none",
      },
      {
        filter_default_label: "Status ...",
        filter_match_mode: "exact",
      },
      {
        filter_default_label: "Eingangszeit ...",
        filter_type: "none",
      },
      {
        filter_default_label: "Quelle ...",
        filter_match_mode: "exact",
      },
      {
        filter_type: "text",
        filter_default_label: "Quelladresse ...",
      },
      {
        filter_default_label: ["Von", "Bis"],
        filter_type: "range_date",
        datepicker_type: "bootstrap-datetimepicker",
        moment_date_format: "DD.MM.YYYY",
        filter_plugin_options: {
          format: "DD.MM.YYYY",
          extraFormats: ["YYYY-MM-DD"],
          locale: "de",
          allowInputToggle: true,
          widgetPositioning: {
            horizontal: "left",
            vertical: "auto",
          },
        },
      },
      {
        filter_type: "text",
        filter_default_label: "Strecke ...",
      },
      {
        filter_type: "text",
        filter_default_label: "Schritte ...",
      },
      {
        filter_type: "text",
        filter_default_label: "Anzahl ...",
      },
      {
        filter_type: "text",
        filter_default_label: "Einrichtung ...",
      },
      {
        filter_type: "text",
      },
    ]);

    const checkBox = $(this.element).find("#all_runs_checkbox");

    checkBox.change(() => {
      let checked = checkBox.prop("checked");
      if (checked) {
        dataTable.rows({ filter: "applied" }).every(function () {
          let input = $(this.node()).find("td.validate> input");
          input.prop("checked", true);
        });
      } else {
        dataTable.rows().every(function () {
          let input = $(this.node()).find("td.validate> input");
          input.prop("checked", false);
        });
      }
    });
  }
}
