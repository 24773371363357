const yadcf = require("yadcf");

function yadcf_wrapper(dataTable, columns) {
  const defaultColumn = {
    style_class: "form-control input-sm",
    reset_button_style_class: "btn btn-sm",
  };

  let newColumns = [];

  columns.forEach(function (column, index) {
    let newColumn = {};
    for (let attrname in defaultColumn) {
      newColumn[attrname] = defaultColumn[attrname];
    }
    for (let attrname in column) {
      newColumn[attrname] = column[attrname];
    }
    newColumn["column_number"] = index;

    if (newColumn["filter_type"] == "range_date") {
      newColumn["style_class"] = null;
    }

    newColumns.push(newColumn);
  });

  yadcf.init(dataTable, newColumns);
}

export default yadcf_wrapper;
