import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String, city_id: String };
  static targets = ["city"];

  connect() {
    $(this.cityTarget).select2({
      placeholder: "Kommune auswählen...",
      language: "de",
      theme: "bootstrap",
      tags: true,
      ajax: {
        delay: 250,
        url: this.urlValue,
        dataType: "json",
        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
      },
    });
  }
}
