import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String, city_id: String };
  static targets = [
    "city",
    "distance_input",
    "steps_input",
    "city_select",
    "city_field",
    "city_link",
    "display_city_select",
  ];

  connect() {
    $(this.cityTarget).select2({
      placeholder: "Kommune auswählen...",
      language: "de",
      theme: "bootstrap",
      ajax: {
        delay: 250,
        url: this.urlValue,
        dataType: "json",
        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
      },
    });

    const distance_field = $(this.distance_inputTarget);
    const steps_field = $(this.steps_inputTarget);

    steps_field.prop("disabled", distance_field.val() !== "");
    distance_field.prop("disabled", steps_field.val() !== "");

    distance_field.on("change paste keyup", () => {
      steps_field.prop("disabled", distance_field.val() !== "");
    });

    steps_field.on("change paste keyup", () => {
      distance_field.prop("disabled", steps_field.val() !== "");
    });

    $(this.city_linkTarget).click((e) => {
      $(this.city_selectTarget).hide();
      $(this.city_fieldTarget).show();
      $(this.display_city_selectTarget).val("0");
      e.preventDefault();
    });
  }
}
