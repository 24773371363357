import { Controller } from "stimulus";
import { useHotkeys } from "stimulus-use";

export default class extends Controller {
  static targets = ["form", "valid", "fraud", "skip", "sensible"];

  connect() {
    super.connect();
    useHotkeys(this, {
      hotkeys: {
        enter: {
          handler: this.enter,
        },
        right: {
          handler: this.skip,
        },
        u: {
          handler: this.invalid,
        },
      },
      filter: this.filter,
    });
  }

  filter(event) {
    if (event.code === 13) {
      return true;
    }

    let target = event.target || event.srcElement;
    let tagName = target.tagName;
    return !(
      target.isContentEditable ||
      tagName == "INPUT" ||
      tagName == "SELECT" ||
      tagName == "TEXTAREA"
    );
  }

  enter(event) {
    event.preventDefault();

    this.validTarget.click();
  }

  skip(event) {
    event.preventDefault();

    this.skipTarget.click();
  }

  invalid(event) {
    event.preventDefault();

    this.fraudTarget.click();
  }
}
