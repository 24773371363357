// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";

Rails.start();
require.context("../images", true);

import "bootstrap-sass/assets/javascripts/bootstrap";

window.JSZip = require("jszip");
import "datatables.net";
import "datatables.net-bs";
import "datatables.net-buttons";
import "datatables.net-buttons-bs";
import "datatables.net-buttons/js/buttons.html5";
import "moment";
import "moment/locale/de";
import "btecu-eonasdan-bootstrap-datetimepicker";
import "select2/dist/js/select2.full";
import "select2/dist/js/i18n/de";
import "bootstrap-jasny/js/fileinput";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("../javascript/controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

import "../javascript/main.js";
