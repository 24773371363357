function datatables_initialize(tableElement) {
  const table = $(tableElement);
  const translation = window.dataTableTranslation;
  const all = translation["oPaginate"]["sAll"];

  let dataTable = table.DataTable({
    paging: true,

    order: [[1, "desc"]],

    stateSave: true,

    columnDefs: [{ orderable: false, targets: "actions" }],
    orderClasses: false,
    // ajax: ...,
    autoWidth: true,
    pageLength: 15,
    lengthMenu: [
      [10, 15, 20, 25, 50, 100, -1],
      [10, 15, 20, 25, 50, 100, all],
    ],
    // pagingType: 'full_numbers',
    // processing: true,
    // serverSide: true,

    // Optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about available options.
    // http://datatables.net/reference/option/pagingType

    language: translation,

    drawCallback: function () {
      $(".dataTables_paginate").parents(".row").addClass("hidden-print");
      $(".dataTables_length").parents(".row").addClass("hidden-print");
    },
  });

  table.children("tbody").on("click", "tr", function (event) {
    if ($(event.target).is("input")) {
      return;
    }
    if ($(event.target).is("a")) {
      return;
    }
    if ($(event.target.parentElement).is("a")) {
      return;
    }
    let url = $(this).data("show-url");
    if (!url) {
      url = dataTable.row(this).data()["show_url"];
    }
    if (url !== undefined) {
      window.open(url, "_self");
    }
  });

  return dataTable;
}

export default datatables_initialize;
